import Endpoint from "./../Utils/Endpoint";
import BanglaBazarApi from "./../Components/Api/BanglaBazarApi";
import { CurrentUser } from "./../Helpers/Auth";

import {
  CART_REQUEST_SUCCESS,
  CART_REQUEST_PENDING,
  CART_REQUEST_FAILED,
  GET_CART_REQUEST_SUCCESS,
  GET_CART_REQUEST_PENDING,
  GET_CART_REQUEST_FAILED,
  REMOVE_CART_REQUEST_SUCCESS,
  REMOVE_CART_REQUEST_PENDING,
  REMOVE_CART_REQUEST_FAILED,
} from "../Constants/Constants";

export const AddCartItems = (data) => async (dispatch) => {
  dispatch({ type: CART_REQUEST_PENDING, payload: [] });

  try {
    var response = await BanglaBazarApi.post(
      `${Endpoint}/api/wish-list/addCart`,
      data
    );
    if (response.data.status) {
      console.log("redux added cart items");
      dispatch({
        type: CART_REQUEST_SUCCESS,
        payload: "Item Added To Cart Successfully",
      });
    } else {
      dispatch({
        type: CART_REQUEST_FAILED,
        payload: response.data.message || response.data.error,
      });
    }
  } catch (e) {
    if (e.response) {
      dispatch({
        type: CART_REQUEST_FAILED,
        payload: e.response.data.message || e.response.data.error,
      });
    } else {
      dispatch({
        type: CART_REQUEST_FAILED,
        payload: "Something went wrong",
      });
    }
  }
};
export const GetCartItems = () => async (dispatch) => {
  if (CurrentUser) {
    try {
      dispatch({ type: GET_CART_REQUEST_PENDING, payload: {} });

      var response = await BanglaBazarApi.get(
        `${Endpoint}/api/wish-list/viewCart`
      );
      if (response.data.status) {
        calculateTotalPriceFromCart(response.data.productCartList, response.data.productCombinationPriceDetail);
        console.log(response.data,'noman2');
        console.log("redux get cart items");
      
        dispatch({
          type: GET_CART_REQUEST_SUCCESS,
          payload: response.data,
        });
        
      } else {
        dispatch({
          type: GET_CART_REQUEST_FAILED,
          payload: response.data.message || response.data.error,
        });
      }
    } catch (e) {
      // console.log(e);
      if (e.response) {
        dispatch({
          type: GET_CART_REQUEST_FAILED,
          payload: e.response.data.message || e.response.data.error,
        });
      } else {
        dispatch({
          type: GET_CART_REQUEST_FAILED,
          payload: "Something went wrong",
        });
      }
    }
  } else {
    dispatch({
      type: GET_CART_REQUEST_SUCCESS,
      payload: {
        status: true,
        productCartList: [],
        productCombinationPriceDetail: [],
      },
    });
  }
};
export const RemoveCartItems = (ProductID, data) => async (dispatch) => {
  dispatch({ type: REMOVE_CART_REQUEST_PENDING, payload: [] });

  try {
    var response = await BanglaBazarApi.post(
      `${Endpoint}/api/wish-list/removeCart/${ProductID}`,
      data
    );
    if (response.data.status) {
      dispatch({
        type: REMOVE_CART_REQUEST_SUCCESS,
        payload: "Cart Item Removed Successfully",
      });
    } else {
      dispatch({
        type: REMOVE_CART_REQUEST_FAILED,
        payload: response.data.message || response.data.error,
      });
    }
  } catch (e) {
    if (e.response) {
      dispatch({
        type: REMOVE_CART_REQUEST_FAILED,
        payload: e.response.data.message || e.response.data.error,
      });
    } else {
      dispatch({
        type: REMOVE_CART_REQUEST_FAILED,
        payload: "Something went wrong",
      });
    }
  }
};

export const calculateTotalPriceFromCart = (productCartList, productCombinationPriceDetail) => {
  let finalAmount = 0
  let finalQuantity = 0
    if(productCartList && productCartList.length > 0){
      productCartList.map((item, index) => {
        let currentProduct = productCartList[index];
        let basePrice = parseFloat(currentProduct.Price);
        const currentDate = new Date();
        const promotionStartDate = new Date(currentProduct.PromotionStartDate);
        const promotionEndDate = new Date(currentProduct.PromotionEndDate);
        if (currentDate >= promotionStartDate && currentDate <= promotionEndDate) {
          if (currentProduct.PromotionPrice > 0) {
            basePrice = parseFloat(currentProduct.PromotionPrice);
          } else if (currentProduct.PromotionRate > 0) {
            const discount = parseFloat(currentProduct.Price) * (currentProduct.PromotionRate / 100);
            basePrice = parseFloat(currentProduct.Price) - discount;
          }
        }

        if (currentProduct.Total_Quantity >= parseFloat(currentProduct.Quantity1) && parseFloat(currentProduct.PriceQuantity1) > 0) {
          basePrice = parseFloat(currentProduct.PriceQuantity1)
        } else if (currentProduct.Total_Quantity >= parseFloat(currentProduct.Quantity2) && parseFloat(currentProduct.PriceQuantity2) > 0) {
          basePrice = parseFloat(currentProduct.PriceQuantity2)
        } else if (currentProduct.Total_Quantity >= parseFloat(currentProduct.Quantity3) && parseFloat(currentProduct.PriceQuantity3) > 0) {
          basePrice = parseFloat(currentProduct.PriceQuantity3)
        }
        let currentCombination = productCombinationPriceDetail[index];
        for (let j = 0; j < currentCombination.length; j++) {
          finalAmount += (basePrice + parseFloat(currentCombination[j].ProductCombinationPrice)) * parseInt(currentProduct.Total_Quantity);
          finalQuantity += parseInt(currentProduct.Total_Quantity);
        }
        localStorage.setItem("totalCartItemsAmountFromViewCart", finalAmount)
        localStorage.setItem("totalCartItemsQuantityFromViewCart", finalQuantity)
      })
    }
}
