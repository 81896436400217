import RatingStars from "./../../../../Helpers/RatingStars";
import Endpoint from "./../../../../Utils/Endpoint";
import { useHistory } from "react-router-dom";

function ODByONProductItem({ product }) {
  const history = useHistory();
  let getSingleTotal = (status) => {
    let array = product.ProductCombinations;
    let basePrice = parseFloat(product.BasePrice);
    let variantValues = [];
    var variationsSum = 0;
    for (let i = 0; i < array.length; i++) {
      variationsSum += parseFloat(array[i].ProductCombinationPrice);
      variantValues.push(array[i].OptionValue);
    }
    const totalSingle = basePrice + variationsSum;

    if (status === 0) {
      return totalSingle;
    } else if (status === 1) {
      return parseFloat(product.Quantity) * totalSingle;
    } else {
      return variantValues.join(",");
    }
  };
  return (
    <>
      <div className="d-flex align-items-center">
        <div style={{ width: "10%", position: "relative" }}>
          {" "}
          <div className="custom-rounded-image">
            <img
              alt=""
              src={`${Endpoint}/${product.Medium}`}
              className="img-fluid"
              style={{}}
              onClick={() =>
                history.push(`/product-details/${product.ProductID}`)
              }
            />
          </div>
        </div>
        <div>
          <div>
            <button
              className="btn p-0"
              onClick={() =>
                history.push(`/product-details/${product.ProductID}`)
              }
            >
              <h6 className="mb-0">{product.Title}</h6>
            </button>

            <div className="">
              <span className="text-secondary">Quantity :</span>{" "}
              {product.Quantity}
            </div>
            <div className="">
              <b>
                Price :{" "}
                {`${product.Currency} ${new Intl.NumberFormat().format(
                  getSingleTotal(1)
                )}`}
              </b>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}
export default ODByONProductItem;
