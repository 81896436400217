import { Link } from "react-router-dom";
import Endpoint from "./../../../../../Utils/Endpoint";
import RatingStars from "./../../../../../Helpers/RatingStars";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HiChevronUp, HiChevronDown } from "react-icons/hi2";
// import {
//   AddCartItems,
//   GetCartItems,
// } from "./../../../../../Actions/CartAction";
import { useDispatch } from "react-redux";
import BanglaBazarApi from "./../../../../Api/BanglaBazarApi";
import firetoast from "./../../../../../Helpers/FireToast";

function CartTableItem({
  product,
  variants,
  removeCartItem,
  index,
  allVariants,
  selectedItemIds,
  setSelectedItemsIds,
  updateSelectedItemsArray,
  updateTotalPrice,
}) {
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const [clickAllowed, setClickAllowed] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  let history = useHistory();

  const updateCounterWithDelay = (operator, value) => {
    if (clickAllowed) {
      // Show loader
      setShowLoader(true);

      // Perform your counter update logic here
      updateCounter(operator, value);

      // Disable further clicks for 1 second
      setClickAllowed(false);
      setTimeout(() => {
        // Hide loader after 1 second
        setShowLoader(false);

        // Enable clicks after hiding loader
        setClickAllowed(true);
      }, 1500);
    }
  };

  useEffect(() => {
    if (product) {
      setCounter(parseInt(product.Total_Quantity));
    }
  }, [product]);

  let getSingleTotal = (status) => {
    //console.log(status, "status---------------------------------");

    let array = variants;
    let basePrice = parseFloat(product.Price);

    const currentDate = new Date();

    // Parse PromotionStartDate and PromotionEndDate if they are strings
    const promotionStartDate = new Date(product.PromotionStartDate);
    const promotionEndDate = new Date(product.PromotionEndDate);
    // Check if the current date is within the promotion period
    if (currentDate >= promotionStartDate && currentDate <= promotionEndDate) {
      if (product.PromotionPrice > 0) {
        basePrice = parseFloat(product.PromotionPrice);
      } else if (product.PromotionRate > 0) {
        const discount =
          parseFloat(product.Price) * (product.PromotionRate / 100);
        basePrice = parseFloat(product.Price) - discount;
      }
    }

    if (
      counter >= parseFloat(product.Quantity1) &&
      parseFloat(product.PriceQuantity1) > 0
    ) {
      basePrice = parseFloat(product.PriceQuantity1);
    } else if (
      counter >= parseFloat(product.Quantity2) &&
      parseFloat(product.PriceQuantity2) > 0
    ) {
      basePrice = parseFloat(product.PriceQuantity2);
    } else if (
      counter >= parseFloat(product.Quantity3) &&
      parseFloat(product.PriceQuantity3) > 0
    ) {
      basePrice = parseFloat(product.PriceQuantity3);
    }

    let variantValues = [];
    var variationsSum = 0;
    for (let i = 0; i < array.length; i++) {
      variationsSum += parseFloat(array[i].ProductCombinationPrice);
      variantValues.push(array[i].OptionValue);
    }
    const totalSingle = basePrice + variationsSum;
    //console.log(totalSingle, "totalSingle------------------------------------");
    if (status === 0) {
      return totalSingle;
    } else if (status === 1) {
      console.log(totalSingle);
      return parseFloat(counter) * totalSingle;
    } else {
      return variantValues.join(",");
    }
  };
  let setInventoryMinCount = () => {
    let array = allVariants;
    let indexes = Object.keys(allVariants);

    let inventories = [];
    for (let i = 0; i < indexes.length; i++) {
      inventories.push(array[indexes[i]].AvailableInventory);
    }
    let count = Math.min(...inventories);
    return count;
  };

  var updateCounter = async (val, singlePrice) => {
    console.log(`single price for this prod is ${singlePrice}`);
    let MinCount = setInventoryMinCount();
    // console.log(MinCount);
    if (MinCount) {
      if (MinCount > 0) {
        if (val === "-") {
          if (counter > 1) {
            await setCounter(counter - 1);
            await updateCount("-1");
            updateTotalPrice(singlePrice, "-", checked);
          }
        } else {
          if (counter < MinCount) {
            await setCounter(counter + 1);
            await updateCount("+1");
            updateTotalPrice(singlePrice, "+", checked);
          }
        }
      }
    }
  };

  const handleQuantityInputChange = (e) => {
    console.log("quantity input change");

    const value = parseInt(e.target.value, 10);

    const stockQty = parseInt(setInventoryMinCount());
    if (!isNaN(value) && value <= stockQty) {
      setCounter(value);
    }
  };

  var updateCount = async (counter) => {
    let dataObject = {};
    let ProductObj = {
      ProductID: product.ProductID,
      Quantity: counter,
    };

    // console.log(ProductObj);
    let ProductDetail = [];
    let indexes = Object.keys(allVariants);
    let SelectedObj = allVariants;
    let ProductVariantCombinationDetail = [];
    for (let i = 0; i < indexes.length; i++) {
      ProductVariantCombinationDetail.push({
        ProductVariantCombinationID:
          SelectedObj[indexes[i]].ProductVariantCombinationID,
      });
    }
    ProductObj.ProductVariantCombinationDetail =
      ProductVariantCombinationDetail;
    ProductDetail.push(ProductObj);
    dataObject.ProductDetail = ProductDetail;
    try {
      const response = await BanglaBazarApi.put(
        `${Endpoint}/api/wish-list/updateCart`,
        dataObject
      );
      if (response.data.status) {
        // dispatch(GetCartItems());
      } else {
        var { error, message } = response.data;
        firetoast(error || message, "default-error");
      }
    } catch (e) {
      firetoast("Something went wrong while updating count", "default-error");
    }
  };

  console.log(" variants[1]", variants[1]);

  console.log(" variants[0]", variants[0]);

  return (
    product && (
      <tr
        id="product_3_13_0_681"
        className="cart_item last_item first_item address_681 odd"
      >
        <td className="cart_product_img">
          <Link to={`/product-details/${product.ProductID}`}>
            <div className="custom-rounded-image">
              <img
                alt=""
                src={`${Endpoint}/${
                  variants[1] ? variants[1].Medium : variants[0].Large
                }`}
                className="img-fluid"
                style={{}}
              />
            </div>
          </Link>
        </td>
        <td className="cart_description">
          <div className="mt-2">
            <b style={{ fontSize: 18 }}>{product.Title}</b>
            <div>
              <span>Vendor</span> : {product.CompanyName}
            </div>
            <div>
              <span className="d-inline-flex align-items-center gap-1">
                {RatingStars(
                  product.AVG_Rating ? parseInt(product.AVG_Rating) : 0
                )}
                ({product.REVIEW_COUNT})
              </span>
            </div>
            {product.AllowStorePickup === "Y" && (
              <div>
                <small>
                  <span className="text-orange">
                    Store pick up available for {product.City}
                  </span>
                </small>
              </div>
            )}
          </div>
        </td>
        <td className="cart_avail text-center">
          <span className="label label-success">In stock</span>
        </td>
        <td className="cart_unit text-center" data-title="Unit price">
          <ul
            className="price"
            id="product_price_3_13_681"
            style={{ marginBottom: 0 }}
          >
            <li className="price">{`${product.Currency} ${parseFloat(
              getSingleTotal(0).toFixed(2)
            )}`}</li>
          </ul>
        </td>
        <td
          className="cart_quantity text-center"
          data-title="Quantity"
          style={{ padding: "0 15px" }}
        >
          <div
            className="d-flex product-quantity"
            style={{ alignItems: "center" }}
          >
            <input
              value={counter}
              type="number"
              className="form-control"
              style={{ width: 60, lineHeight: "31px" }}
              onChange={handleQuantityInputChange}
            />
            <button
              className="counter-btn plus-btn"
              onClick={() =>
                updateCounterWithDelay(
                  "+",
                  parseFloat(getSingleTotal(0).toFixed(2))
                )
              }
            >
              <HiChevronUp />
            </button>
            <button
              className="counter-btn minus-btn"
              onClick={() =>
                updateCounterWithDelay(
                  "-",
                  parseFloat(getSingleTotal(0).toFixed(2))
                )
              }
            >
              <HiChevronDown />
            </button>
          </div>
        </td>
        <td className="cart_delete text-center" data-title="Delete">
          <div>
            <Link
              to="#"
              onClick={() => removeCartItem(product.ProductID, index)}
              rel="nofollow"
              title="Delete"
              className="cart_quantity_delete"
              id="3_13_0_681"
            >
              <i className="icon-trash" style={{ color: "red" }} />
            </Link>
          </div>
        </td>
        <td className="cart_total text-right" data-title="Total">
          <span className="price" id="total_product_price_3_13_681">
            {`${product.Currency} ${parseFloat(getSingleTotal(1).toFixed(2))}`}
          </span>
        </td>
      </tr>
    )
  );
}
export default CartTableItem;
