import { Row, Col } from "reactstrap";
import Skeleton from "@mui/material/Skeleton";

function ProductSkeleton() {
  return (
    <Row className="mt-3">
      <Col lg="3" xs="12">
        <Skeleton variant="rectangular" height={118} animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </Col>
      <Col lg="3" xs="12">
        <Skeleton variant="rectangular" height={118} animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </Col>
      <Col lg="3" xs="12">
        <Skeleton variant="rectangular" height={118} animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </Col>
      <Col lg="3" xs="12">
        <Skeleton variant="rectangular" height={118} animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </Col>
    </Row>
  );
}
export default ProductSkeleton;
