import logo from "../../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import Signup from "./../Modals/Signup";
import { CurrentUser } from "./../../../../Helpers/Auth";
import Signin from "./../Modals/Signin";
import { useHistory } from "react-router-dom";
import firetoast from "../../../../../src/Helpers/FireToast";
import { Cu } from "react-flags-select";
import BanglaBazarApi from "Components/Api/BanglaBazarApi";
import Endpoint from "Utils/Endpoint";
import SSLCommerz from "assets/images/sslcommerz-logo.png";

export function Footer() {
  const history = useHistory();
  const [signup, setSignup] = useState(false);
  const [signin, setSignin] = useState(false);
  const [otpmodal, setOtpModal] = useState(false);
  var reload = () => {
    window.location.reload();
  };
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const saveNewsLetter = async () => {
    if (email.trim() === "") {
      return firetoast(
        "Please provide an email address",
        "info",
        4000,
        "top-center"
      );
    }
    try {
      let response = await BanglaBazarApi.post(
        `${Endpoint}/api/news-letter/add`,
        {
          email_address: email,
        }
      );
      if (response.data.status) {
        firetoast(
          "Thank you for subscribing to our newsletter! You've been added to our mailing list and will receive updates and special offers from us. We appreciate your interest in our products/services",
          "info",
          6000,
          "top-center"
        );
      } else {
        firetoast(`${response.data.message}`, "info", 4000, "top-right");
      }
    } catch (e) {
      firetoast(`${e.message}`, "error", 4000, "top-right");
    }
  };

  const links = [
    { to: "/privacy-policy", text: "Privacy Policy" },
    { to: "/terms-conditions", text: "Terms and Conditions" },
    { to: "/about-us", text: "About Us" },
    { to: "/refund-and-return", text: "Refund Policy" },
  ];

  return (
    <footer>
      <div className="footer-container " style={{ marginTop: 20 }}>
        <div className="footer-container-inner">
          <footer id="footer" className="container">
            <div className="row">
              <section
                className="footer-block col-xs-12 col-sm-3"
                id="block_various_links_footer"
              >
                <h4>Quick Links</h4>
                <ul
                  className="toggle-footer bullet noSwipe"
                  style={{ paddingLeft: "0px" }}
                >
                  {links.map((link) => (
                    <li className="item" key={link.to}>
                      <Link to={link.to}>{link.text}</Link>
                    </li>
                  ))}
                </ul>
              </section>
              <section className="footer-block col-xs-12 col-sm-3">
                <h4>My account</h4>
                <div className="block_content toggle-footer" style={{}}>
                  <ul className="bullet noSwipe">
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          console.log(CurrentUser, "CurrentUser");
                          if (CurrentUser) {
                            history.push("/my-cart");
                          } else {
                            console.log("else part");
                            firetoast(
                              "Login first to view your cart",
                              "info",
                              5000,
                              "top-center"
                            );
                          }
                        }}
                      >
                        View Cart
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          if (CurrentUser) {
                            history.push("/my-wishlist");
                          } else {
                            firetoast(
                              "Login first to view your wishlist",
                              "info",
                              5000,
                              "top-center"
                            );
                          }
                        }}
                        style={{ color: "#ababab" }}
                      >
                        My Wishlist
                      </Link>
                    </li>
                    {!CurrentUser && (
                      <li className="">
                        {" "}
                        <Link
                          to="#"
                          className="td-none"
                          style={{ color: "#ababab" }}
                          onClick={() => setSignup(true)}
                        >
                          Sign up
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </section>
              <section
                id="block_contact_infos"
                className="footer-block col-xs-12 col-sm-3"
              >
                <div>
                  <h4>Customer Service</h4>
                  <ul className="toggle-footer bullet noSwipe" style={{}}>
                    <li className="footer-Links-heading-child">
                      <Link
                        to="#"
                        onClick={() => {
                          if (CurrentUser) {
                            history.push("/sell");
                          } else {
                            setSignin(!signin);
                          }
                        }}
                      >
                        Become a Vendor
                      </Link>
                    </li>
                    <li className="item">
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </section>
              <section
                id="social_block_mod"
                className="social_block_mod footer-block col-xs-12 col-sm-3"
              >
                <div>
                  <h4 className="removeBottomMargin">Follow us</h4>
                  {/* <h5>
                    <span>
                      {" "}
                      <img src={logo} className="img-fluid mb-1 logo" />
                    </span>
                  </h5> */}
                  <div>
                    <span style={{ marginRight: "10px" }}>
                      <button
                        className="rounded-circle footer-social-icon footer-icon-fb mb-2"
                        onClick={() =>
                          window.open(
                            "https://www.facebook.com/BanglaBazarMarketPlace"
                          )
                        }
                      >
                        <i className="fab fa-facebook"></i>
                      </button>
                    </span>
                    <span style={{ marginRight: "10px" }}>
                      <button
                        className="rounded-circle footer-social-icon footer-icon-ig mb-2"
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/banglabazarmarketplace"
                          )
                        }
                      >
                        <i className="fab fa-instagram"></i>
                      </button>
                    </span>

                    <span style={{ marginRight: "10px" }}>
                      <button
                        className="rounded-circle footer-social-icon footer-icon-twitter"
                        onClick={() =>
                          window.open("https://twitter.com/BanglaBazarCom")
                        }
                      >
                        <i className="fab fa-twitter"></i>
                      </button>
                    </span>
                    <span style={{ marginRight: "10px" }}>
                      <button
                        className="rounded-circle footer-social-icon footer-icon-yt"
                        onClick={() =>
                          window.open(
                            "https://www.youtube.com/@BanglaBazarMarketPlace"
                          )
                        }
                      >
                        <i className="fab fa-youtube"></i>
                      </button>
                    </span>
                    <span style={{ marginRight: "10px" }}>
                      <button
                        className="rounded-circle footer-social-icon footer-icon-tiktok"
                        onClick={() =>
                          window.open("https://www.tiktok.com/@banglabazar.com")
                        }
                      >
                        <i className="fab fa-tiktok"></i>
                      </button>
                    </span>
                  </div>
                  <div className="mb-3" style={{ marginTop: 15 }}>
                    <h4
                      className="removeUnderline"
                      // style={{ paddingBottom: 0 }}
                    >
                      Newsletter
                    </h4>
                    <div
                      className="input-group mb-3 newsLetter"
                      style={{ height: 30 }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your e-mail"
                        aria-label="email"
                        aria-describedby="basic-addon1"
                        style={{ borderRight: "none" }}
                      />
                      <span
                        className="input-group-text"
                        id="basic-addon1"
                        style={{ background: "white" }}
                      >
                        <button
                          className="btn"
                          onClick={saveNewsLetter}
                          style={{ paddingRight: 0 }}
                        >
                          <i className="fa fa-angle-right custom-icon"></i>
                        </button>
                      </span>
                    </div>

                    <span class="promo-text">
                      Sign up to receive latest news and updates direct to your
                      inbox
                    </span>

                    {/* <input
                      style={{ lineHeight: 2 }}
                      className="inputNew form-control grey newsletter-input"
                      id="newsletter-input"
                      type="text"
                      name="email"
                      onChange={handleEmailChange}
                      size={18}
                      placeholder="Enter your e-mail"
                    />
                    <button
                      type="submit"
                      name="submitNewsletter"
                      className="btn btn-sm btn-secondary"
                      onClick={saveNewsLetter}
                    >
                      <span>Subscribe</span>
                    </button> */}
                  </div>
                </div>
              </section>
            </div>
          </footer>
        </div>
        <div className="footer_copyrights">
          <footer className="container clearfix">
            <div className="row w-100">
              <div className="col-6">
                <h4 className="mt-2 ftw-400">Delivery Partners</h4>
                <div className="row align-items-center">
                  {localStorage.getItem("region") === "Bangladesh" && (
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/5/5b/Pathao-logo.jpg"
                        width="150"
                      />
                    </div>
                  )}
                  {localStorage.getItem("region") === "United States" && (
                    <>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <img
                          src="https://www.seekpng.com/png/detail/942-9422924_united-states-postal-service-logo-usps-png-logo.png"
                          width="150"
                          height="60"
                        />
                      </div>
                    </>
                  )}
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <img
                      src="https://logodownload.org/wp-content/uploads/2015/12/dhl-logo.png"
                      width="150"
                      height="60"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <h4 className="mt-2 ftw-400">Payment Partners</h4>
                <div className="row align-items-center">
                  {localStorage.getItem("region") === "Bangladesh" && (
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <img src={SSLCommerz} className="img-fluid" />
                    </div>
                  )}

                  {localStorage.getItem("region") !== "Bangladesh" && (
                    <>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <img
                          src="https://logos-world.net/wp-content/uploads/2021/03/Stripe-Logo.png"
                          width="150"
                          height="60"
                        />
                      </div>
                      {/* <div className="col-lg-4 col-md-6 col-sm-12">
                    <img
                      src="https://merchantmachine.co.uk/wp-content/uploads/Authorize-net-logo.png"
                      width="150"
                      height="60"
                    />
                  </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div
          className="footer_copyrights"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <footer className="container clearfix">
            <div className="row">
              <div className=" col-sm-12" style={{ textAlign: "center" }}>
                <p style={{ marginBottom: 0 }}>
                  Copyright&#169; 2021 BanglaBazar. All Rights Reserved.
                </p>
              </div>
            </div>
          </footer>
        </div>
      </div>

      <Signin
        signin={signin}
        setSignIn={setSignin}
        signup={signup}
        setSignUp={setSignup}
        reload={reload}
      />
      <Signup
        signin={signin}
        setSignIn={setSignin}
        signup={signup}
        setSignUp={setSignup}
        otpmodal={otpmodal}
        setOtpModal={setOtpModal}
      />
    </footer>
  );
}
