import { ToastContainer } from "react-toastify";
import { CurrentUser } from "Helpers/Auth";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { GetCartItems, RemoveCartItems } from "Actions/CartAction";

import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  ListInlineItem,
  Input,
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { PhoneVerificationAlert } from "../PhoneVerificationAlert";
import { useLocation, useHistory, Link } from "react-router-dom";
import "assets/css/new-template.css";
import "assets/css/custom.css";
import logo from "assets/images/logo.png";
import NavBar from "./NavBar";
// import { useHistory } from "react-router-dom";
import MODAL_CONTEXT from "Components/Contexts/ModalContext";
import BanglaBazarApi from "Components/Api/BanglaBazarApi";
import Endpoint from "Utils/Endpoint";
import { useParams } from "react-router";
import firetoast from "Helpers/FireToast";
import { useDispatch, useSelector } from "react-redux";
import CheckEmpty from "Utils/CheckEmpty";
import MyCart from "./MyCart";
import Select from "react-select";
import { event } from "jquery";

export function WebsiteHeader() {
  var { signin, setSignin } = useContext(MODAL_CONTEXT);

  const [otpmodal, setOtpModal] = useState(false);
  const [show, setShow] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [collapsed, setCollapsed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const [Region, setRegion] = useState(
    localStorage.getItem("region") ? localStorage.getItem("region") : null
  );

  //COUNTRIES MAP
  const [dropdownOpenCountry, setDropdownOpenCountry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [countries, setCountries] = useState([]);

  const dispatch = useDispatch();
  const { getCartItem, removeCartItem } = useState([]);
  const caar = useSelector((state) => state.getCartItem.data);
  const getCountryList = async (id) => {
    try {
      var response = await BanglaBazarApi.get(
        Endpoint + "/api/landing-page/countryCurrencyList"
      );
      const countriesData = response.data.currencyList;

      var dataString = JSON.stringify(countriesData);

      // Encode the data using base64 encoding
      var encodedData = window.btoa(dataString);

      // Store the encoded data in localStorage
      localStorage.setItem("allCountriesList", JSON.stringify(countriesData));

      // const countriesWithFlags = countriesData.map((country) => ({
      //   ...country,
      //   // flagUrl: `https://flagcdn.com/32x24/${country.ISO2.toLowerCase()}.png`,
      //    flagUrl: `https://img.mobiscroll.com/demos/flags/${country.ISO2.toLowerCase()}.png`,
      // }));

      const countriesWithFlags = countriesData.map((country) => {
        // console.log(country)
        return {
          // label : <>
          //           <p className="mb-0 d-flex align-items-center gap-2">
          //             <img src={`https://flagsapi.com/${country.ISO2}/flat/32.png`} alt="img" style={{width: 20,height: 20, objectFit : 'cover',objectPosition : 'center'}}/>
          //             <span className="text-uppercase">{country?.IOSCountryCode}</span>
          //         </p>
          //     </>,
          label: country?.IOSCountryCode,
          value: {
            ...country,
            flagUrl: `https://flagsapi.com/${country.ISO2}/flat/32.png`,
          },
        };
      });

      setCountries(countriesWithFlags);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    const savedCountry =
      JSON.parse(localStorage.getItem("persistedCountry")) || null;
    setTimeout(() => {
      if (savedCountry) {
        setSelectedCountry({
          // label : <p className="mb-0 d-flex align-items-center gap-2">
          //       <img src={`https://flagsapi.com/${savedCountry?.ISO2}/flat/32.png`} alt="img" style={{width: 20,height: 20, objectFit : 'cover',objectPosition : 'center'}}/>
          //       <span className="text-uppercase">{savedCountry?.IOSCountryCode}</span>
          //   </p>,
          label: savedCountry?.IOSCountryCode,
          value: savedCountry,
        });
      } else {
        const defaultIso = localStorage.getItem("iso") || "";
        const defaultRegion = localStorage.getItem("region") || "";
        const allCountriesList = JSON.parse(
          localStorage.getItem("allCountriesList") || "[]"
        );

        const IOSCountry = allCountriesList.find((c) => {
          console.log(c.ISO2, defaultIso, "ccccc---");
          return c.ISO2.toLowerCase() == defaultIso.toLowerCase();
        });
        // console.log(IOSCountry, 'IOSCountryCode--')
        setSelectedCountry({
          // label : <p className="mb-0 d-flex align-items-center gap-2">
          //       <img src={getFlagUrlByIsoCode(defaultIso)} alt="img" style={{width: 20,height: 20, objectFit : 'cover',objectPosition : 'center'}}/>
          //       <span className="text-uppercase">{IOSCountry.IOSCountryCode}</span>
          //   </p>,
          label: IOSCountry?.IOSCountryCode,
          value: {
            IOSCountryCode: IOSCountry?.IOSCountryCode,
            iso: defaultIso,
            region: defaultRegion,
          },
        });
      }
    }, 100);
  }, [localStorage.getItem("region")]);

  const formatOptionLabel = (option) => {
    return (
      <p className="mb-0 d-flex align-items-center gap-2">
        {option?.value?.ISO2 ? (
          <img
            src={`https://flagsapi.com/${option?.value?.ISO2}/flat/32.png`}
            alt="img"
            style={{
              width: 20,
              height: 20,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        ) : (
          <img
            src={getFlagUrlByIsoCode(option?.value?.iso)}
            alt="img"
            style={{
              width: 20,
              height: 20,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        )}

        <span className="text-uppercase">{option?.value?.IOSCountryCode}</span>
      </p>
    );
  };

  const handleSelect = (country) => {
    let reload = true;
    const cartAvailable = caar && caar.productCartList.length > 0;
  
    if (cartAvailable) {
      reload = false;
      Swal.fire({
        title: 'Are you sure?',
        text: 'Your shopping cart will be removed since you are changing the shopping destination country',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const productCombinationPriceDetail = caar.productCombinationPriceDetail[0];
          const products = caar.productCartList;
  
          // Prepare ProductVariantCombinationDetail for each product
          const ProductVariantCombinationDetail = products.map((product) =>
            productCombinationPriceDetail.map((variation) => ({
              ProductVariantCombinationID: variation.ProductVariantCombinationID,
            }))
          );
  
          // Flatten the array if needed
          const flattenedDetails = ProductVariantCombinationDetail.flat();
  
          // Dispatch RemoveCartItems action
          for (const product of products) {
            await dispatch(
              RemoveCartItems(product.ProductID, { ProductVariantCombinationDetail: flattenedDetails })
            );
          }
  
          // Proceed with the reload
          finalizeReload(country, true); // Indicate cart has been deleted
        } else {
          console.log("User canceled the operation.");
        }
      });
    } else {
      // No cart available; proceed directly
      finalizeReload(country, false);
    }
  };
  
  const finalizeReload = (country, deleted) => {
    const { value: countryName } = country;
  
    localStorage.setItem(
      "setSelectedCountry",
      countryName.Country.toLowerCase()
    );
  
    if (countryName.Country === "Bangladesh") {
      localStorage.setItem("region", "Bangladesh");
    } else {
      localStorage.setItem("region", "United States");
    }
  
    localStorage.setItem("currency", countryName.CurrencyCode);
    localStorage.setItem("iso", countryName.ISO2);
    localStorage.setItem("persistedCountry", JSON.stringify(countryName));
    localStorage.setItem("DCID", countryName.CountryID);
    setSelectedCountry(country);
    setDropdownOpenCountry(false);
  
    setTimeout(() => {
      if (deleted) {
        window.location.href = "/";
      } else {
        window.location.reload();
      }
    }, 100); // Small delay to ensure operations complete
  };

  const toggleCountry = () => setDropdownOpenCountry(!dropdownOpenCountry);

  const getFlagUrlByIsoCode = (isoCode) => {
    // Capitalize the isoCode
    const capitalizedIsoCode = isoCode.toUpperCase();
    // Return the URL with the capitalized isoCode
    return `https://flagsapi.com/${capitalizedIsoCode}/flat/32.png`;
  };

  //COUNTRIES MAP END
  const toggleNav = () => setIsOpen(!isOpen);
  var history = useHistory();
  var location = useLocation();
  var param = useParams().toggle;
  // console.log(location);

  const isOrderDetailsRoute = location.pathname.includes("/order-details");

  useEffect(() => {
    setCurrentUser(CurrentUser);
    if (CurrentUser) getUserNotification();
    if (location.pathname.includes("/home")) {
      // setSignIn(true);
      setSignin(true);
    }
  }, []);

  var reload = () => {
    window.location.reload();
  };

  var getUserNotification = async () => {
    try {
      var response = await BanglaBazarApi.get(
        `${Endpoint}/api/admin/notifications`
      );
      setNotifications(response.data.notifications);

      console.log("NOTIFICATIONS===============>", response.data.notifications);
    } catch (e) {
      // console.log(e);
    }
  };

  var logout = async () => {
    await BanglaBazarApi.post(`${Endpoint}/api/user/logout`, {
      SessionID: localStorage.getItem("accessToken"),
    });

    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");

    setTimeout(() => {
      window.location.href = "/";
    }, 0);
  };

  const handleNotficationChat = (id) => {
    localStorage.setItem("userID", JSON.stringify(id));
    console.log("=================>", id);
    history.push(`/my-chats`);

    console.log("PUSHED===========>");
    // history.push(`/chat-support/${id}`)
  };

  console.log("countries", countries.country);

  // search category start

  const [SelectedCategory, setSelectedCategory] = useState({
    Category: "All",
    CategoryID: "All",
  });
  const [SearchInput, setSearchInput] = useState("");
  const state = useSelector((state) => state);
  const { categoriesAndSubcategories } = state;
  const [CategoryAndSubCategory, setCategoryAndSubCategory] = useState([]);
  const [isMobileNavShow, setIsMobileNavShow] = useState(false);

  useEffect(() => {
    if (categoriesAndSubcategories.error) {
      firetoast(categoriesAndSubcategories.error, "default-error");
      setCategoryAndSubCategory([]);
    } else {
      setCategoryAndSubCategory(
        categoriesAndSubcategories.categoriesAndSubCategories
      );
    }
  }, [categoriesAndSubcategories]);

  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      // Trigger your desired action
      console.log("Enter key was pressed");
      console.log("Input value:", SearchInput);
      // Perform any other actions here, such as calling an API
      if (CheckEmpty(SearchInput)) {
        history.push(
          `/search/global/products/${SelectedCategory.CategoryID}?keyword=`
        );
      } else {
        history.push(
          `/search/global/products/${SelectedCategory.CategoryID}?keyword=${SearchInput}`
        );
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <PhoneVerificationAlert />
      <div className="header-container">
        <header id="header">
          <div>
            <div className="container">
              <div
                id="desktop-header"
                className="desktop-header d-block pb-4 pb-lg-0"
              >
                <div className="row align-items-center">
                  <div className="col-12 col-lg-3 order-2 order-lg-1">
                    <div
                      id="header-logo"
                      className="h-auto d-flex align-items-center justify-content-between gap-2 d-lg-block text-center text-lg-start pt-3 pt-lg-0 position-relative"
                    >
                      <span
                        className="d-block d-lg-none"
                        role="button"
                        onClick={() => setIsMobileNavShow(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          class="bi bi-list"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                          />
                        </svg>
                      </span>
                      <img
                        src={logo}
                        alt="banglabazar logo"
                        className="img-fluid logo"
                        onClick={() => history.push("/")}
                      />
                      <div className="user-and-wishlist d-flex d-lg-none align-items-center gap-2">
                        <div className="d-flex align-items-center justify-content-lg-end mt-lg-2">
                          <div>
                            {currentUser ? (
                              <>
                                <ListInlineItem
                                  className="ms-3 me-2"
                                  style={{
                                    marginTop: "3px !important",
                                  }}
                                >
                                  <span
                                    className="mycart-text"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => history.push("/my-chats")}
                                  >
                                    <i className="fas fa-user me-1"></i>
                                    Chats
                                  </span>
                                </ListInlineItem>
                                <span class="log-separator">/</span>
                                <ListInlineItem
                                  className="ms-3"
                                  style={{
                                    marginTop: "3px !important",
                                  }}
                                >
                                  <span
                                    className="mycart-text"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      history.push(
                                        `/user-profile/${CurrentUser.UserID}`
                                      );
                                    }}
                                  >
                                    <i className="fas fa-user me-1"></i>
                                    {currentUser.UserName.split(" ")[0]}{" "}
                                  </span>
                                </ListInlineItem>
                                <span class="log-separator">/</span>
                                <ListInlineItem
                                  className="ms-3"
                                  style={{
                                    marginTop: "3px !important",
                                  }}
                                >
                                  <span
                                    className="mycart-text"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      logout();
                                    }}
                                  >
                                    <i class="fas fa-right-to-bracket"></i>
                                    {/* <i className="fas fa-sign-in text-default user-icon pe-1"></i> */}
                                    Sign out
                                  </span>
                                </ListInlineItem>
                              </>
                            ) : (
                              <>
                                <ListInlineItem
                                  className="ms-3"
                                  style={{
                                    marginTop: "3px !important",
                                  }}
                                >
                                  <span
                                    className="mycart-text"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setSignin(!signin)}
                                  >
                                    <i className="far fa-user text-default user-icon pe-1"></i>
                                    Sign in / Register
                                  </span>
                                </ListInlineItem>
                              </>
                            )}

                            {currentUser &&
                              (currentUser.Admin === "Y" ||
                                currentUser.SuperAdmin === "Y" ||
                                currentUser.Vendor === "Y") && (
                                <>
                                  {(currentUser.Admin === "Y" ||
                                    currentUser.SuperAdmin === "Y" ||
                                    currentUser.Vendor === "Y") && (
                                      <>
                                        <span class="log-separator">/</span>
                                        <ListInlineItem>
                                          <span
                                            className=" p-1"
                                            onClick={() =>
                                              history.push("/panel/dashboard")
                                            }
                                            style={{
                                              // fontSize: "16px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fas fa-user-alt me-1"
                                              onClick={() =>
                                                history.push("/panel/dashboard")
                                              }
                                            ></i>
                                            Dashboard
                                          </span>
                                        </ListInlineItem>
                                      </>
                                    )}
                                </>
                              )}
                          </div>
                        </div>
                        <a
                          href="javascript:void()"
                          title="My wishlist"
                          className="wishlist_top_link me-auto me-lg-0"
                          style={{
                            display: "inline",
                            color: "#999999",
                          }}
                          onClick={() => {
                            if (CurrentUser) {
                              history.push("/my-wishlist");
                            } else {
                              firetoast(
                                "Login first to view your wishlist",
                                "info",
                                5000,
                                "top-center"
                              );
                            }
                          }}
                        >
                          <i className="far fs-5 fa-heart text-secondary" />
                        </a>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5 order-3 order-lg-2">
                    <div
                      id="search_block_top_content"
                      className="d-flex align-items-center gap-3 d-lg-block"
                    >
                      <div className="iqitsearch-inner flex-grow-1">
                        <p style={{ textAlign: "center" }}>
                          <em className="fas fa-plane-departure">&nbsp;</em>
                          &nbsp; International shipping &nbsp; &nbsp;&nbsp;
                          <em className="fas fa-credit-card">&nbsp;&nbsp;</em>
                          Secure payment
                        </p>

                        <div
                          id="search_block_top"
                          className="search_block_top issearchcontent iqit-search w-100"
                        >
                          <div className="search_query_container search-w-selector">
                            <div className="search-cat-selector">
                              <select
                                className="form-control search-cat-select"
                                name="search_query_cat"
                              >
                                <option value="0">All categories</option>
                                {CategoryAndSubCategory.map((cat, subIndex) => (
                                  <option
                                    key={subIndex}
                                    onClick={() => {
                                      let obj = {
                                        Active: cat.Active,
                                        Category: cat.Category,
                                        CategoryID: cat.CategoryID,
                                        CategoryPic: cat.CategoryPic,
                                        DepartmentID: cat.DepartmentID,
                                        Description: cat.Description,
                                        LastUpdate: cat.LastUpdate,
                                        ShippingGlobal: cat.ShippingGlobal,
                                      };
                                      setSelectedCategory(obj);
                                      console.log("===========>", obj);
                                      // setSelectedCategory(cat.Category_Details)
                                      // console.log("===========>", Category);
                                    }}
                                  >
                                    {cat.Category}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <Input
                              className="search_query form-control"
                              placeholder="Search ..."
                              onChange={(e) => setSearchInput(e.target.value)}
                              onKeyDown={handleSearchKeyDown}
                            // onKeyDown={() => {
                            //   if (CheckEmpty(SearchInput)) {
                            //     history.push(
                            //       `/search/global/products/${SelectedCategory.CategoryID}?keyword=`
                            //     );
                            //   } else {
                            //     history.push(
                            //       `/search/global/products/${SelectedCategory.CategoryID}?keyword=${SearchInput}`
                            //     );
                            //   }
                            // }}
                            />
                            {/* <input
                                  className="search_query form-control"
                                  type="text"
                                  id="search_query_top"
                                  name="search_query"
                                  placeholder="Search"
                                  value=""
                                /> */}
                            <button
                              type="submit"
                              name="submit_search"
                              className="button-search"
                              onClick={() => {
                                if (CheckEmpty(SearchInput)) {
                                  history.push(
                                    `/search/global/products/${SelectedCategory.CategoryID}?keyword=`
                                  );
                                } else {
                                  history.push(
                                    `/search/global/products/${SelectedCategory.CategoryID}?keyword=${SearchInput}`
                                  );
                                }
                              }}
                            >
                              <span>Search</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 order-1 order-lg-3">
                    <div className="mt-3 mt-lg-0 login-and-wishlist d-flex flex-row-reverse d-lg-block gap-2 align-items-center justify-content-between">
                      <div className="d-flex flex-row-reverse flex-lg-row gap-2 flex-grow-1 flex-wrap align-items-center justify-content-lg-end">
                        <a
                          href="javascript:void()"
                          title="My wishlist"
                          className="wishlist_top_link me-auto me-lg-0 d-none d-lg-inline"
                          style={{
                            display: "inline",
                            color: "#999999",
                          }}
                          onClick={() => {
                            if (CurrentUser) {
                              history.push("/my-wishlist");
                            } else {
                              firetoast(
                                "Login first to view your wishlist",
                                "info",
                                5000,
                                "top-center"
                              );
                            }
                          }}
                        >
                          <i className="far fa-heart text-secondary me-lg-1" />
                          <span className="d-none d-lg-inline">
                            My Wishlist
                          </span>
                        </a>{" "}
                        <ListInlineItem>
                          <MyCart />
                        </ListInlineItem>
                        <ListInlineItem className="me-auto me-lg-0">
                          <div
                            className="countries-dropdown position-relative"
                            style={{ zIndex: 2 }}
                          >
                            <Select
                              value={selectedCountry}
                              onChange={(c) => {
                                if (isOrderDetailsRoute) {
                                  toast.error(
                                    "You Cannot Convert the Currency in which Product was purchased ",
                                    {
                                      position: "top-right",
                                      autoClose: 4000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                } else {
                                  handleSelect(c);
                                }
                              }}
                              options={countries}
                              formatOptionLabel={formatOptionLabel}
                              isSearchable={true}
                              classNamePrefix="reactSelect-country"
                            />
                            {/* <UncontrolledButtonDropdown direction="left">
                                <Dropdown
                                  isOpen={dropdownOpenCountry}
                                  toggle={toggleCountry}
                                >
                                  <DropdownToggle
                                    className="btn-light bg-none flag-top-icon"
                                    style={{
                                      background: "transparent",
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={getFlagUrlByIsoCode(
                                          selectedCountry
                                        )}
                                        alt={`Flag of ${selectedCountry}`}
                                      />
                                      <i className="fas fa-chevron-down text-dark"></i>
                                    </div>
                                  </DropdownToggle>

                                  <DropdownMenu>
                                    {countries.length > 0 &&
                                      countries.map((country) => (
                                        <DropdownItem
                                          key={country.name}
                                          onClick={(e) => {
                                            if (isOrderDetailsRoute) {
                                              e.preventDefault(); // Prevent default if on 'order-details' route
                                              toast.error(
                                                "You Cannot Convert the Currency in which Product was purchased ",
                                                {
                                                  position: "top-right",
                                                  autoClose: 4000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            } else {
                                              handleSelect(country);
                                            }
                                          }}
                                          // Disable item if on 'order-details' route
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          <img
                                            src={country.flagUrl}
                                            alt={country.name}
                                            style={{
                                              marginRight: "7px",
                                            }}
                                          />
                                          {country.IOSCountryCode}
                                        </DropdownItem>
                                      ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </UncontrolledButtonDropdown> */}
                          </div>
                        </ListInlineItem>
                      </div>
                      <div className="d-none d-lg-flex align-items-center justify-content-lg-end mt-lg-2">
                        <div>
                          {currentUser ? (
                            <>
                              <ListInlineItem
                                className="ms-3 me-2"
                                style={{
                                  marginTop: "3px !important",
                                }}
                              >
                                <span
                                  className="mycart-text"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => history.push("/my-chats")}
                                >
                                  <i className="fas fa-comment me-1"></i>
                                  Chats
                                </span>
                              </ListInlineItem>
                              <span class="log-separator">/</span>
                              <ListInlineItem
                                className="ms-3"
                                style={{
                                  marginTop: "3px !important",
                                }}
                              >
                                <span
                                  className="mycart-text"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    history.push(
                                      `/user-profile/${CurrentUser.UserID}`
                                    );
                                  }}
                                >
                                  <i className="fas fa-user me-1"></i>
                                  {currentUser.UserName.split(" ")[0]}{" "}
                                </span>
                              </ListInlineItem>
                              <span class="log-separator">/</span>
                              <ListInlineItem
                                className="ms-3"
                                style={{
                                  marginTop: "3px !important",
                                }}
                              >
                                <span
                                  className="mycart-text"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    logout();
                                  }}
                                >
                                  <i class="fas fa-sign-out-alt me-1"></i>
                                  {/* <i className="fas fa-sign-in text-default user-icon pe-1"></i> */}
                                  Sign out
                                </span>
                              </ListInlineItem>
                              {/* <ListInlineItem>
                                <span
                                  className="lt-text"
                                  style={{ cursor: "pointer" }}
                                >
                                  <UncontrolledButtonDropdown direction="center">
                                    <DropdownToggle
                                      className=" btn-light text-default bg-transparent"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {currentUser.UserName.split(" ")[0]}{" "}
                                      <i
                                        className="fas fa-chevron-down text-dark "
                                        style={{ fontSize: "13px" }}
                                      ></i>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="landing-nav-toggle"
                                      style={{ fontSize: "13px" }}
                                    >
                                      <DropdownItem
                                        onClick={() => {
                                          history.push(
                                            `/user-profile/${CurrentUser.UserID}`
                                          );
                                        }}
                                      >
                                        Profile
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          history.push("/my-chats")
                                        }
                                      >
                                        Chats
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          logout();
                                        }}
                                      >
                                        Logout
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledButtonDropdown>
                                </span>
                              </ListInlineItem> */}
                            </>
                          ) : (
                            <>
                              <ListInlineItem
                                className="ms-3"
                                style={{
                                  marginTop: "3px !important",
                                }}
                              >
                                <span
                                  className="mycart-text"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setSignin(!signin)}
                                >
                                  <i className="far fa-user text-default user-icon pe-1"></i>
                                  Sign in / Register
                                </span>
                              </ListInlineItem>
                            </>
                          )}

                          {currentUser &&
                            (currentUser.Admin === "Y" ||
                              currentUser.SuperAdmin === "Y" ||
                              currentUser.Vendor === "Y") && (
                              <>
                                {(currentUser.Admin === "Y" ||
                                  currentUser.SuperAdmin === "Y" ||
                                  currentUser.Vendor === "Y") && (
                                    <>
                                      <span class="log-separator">/</span>
                                      <ListInlineItem>
                                        <span
                                          className=" p-1"
                                          onClick={() =>
                                            history.push("/panel/dashboard")
                                          }
                                          style={{
                                            // fontSize: "16px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fas fa-user-alt me-1"
                                            onClick={() =>
                                              history.push("/panel/dashboard")
                                            }
                                          ></i>
                                          Dashboard
                                        </span>
                                      </ListInlineItem>
                                    </>
                                  )}
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="iqitfreedeliverycount iqitfreedeliverycount-detach hidden-detach clearfix ">
                    <div clas="fd-table">
                      <div className="ifdc-icon fd-table-cell">
                        <i className="icon icon-truck"></i>
                      </div>
                      <div className="ifdc-remaining fd-table-cell">
                        Spend{" "}
                        <span className="ifdc-remaining-price">$200.00</span>
                        more and get Free Shipping!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="navbar-component">
                <NavBar
                  isMobileNavShow={isMobileNavShow}
                  callback={(is) => {
                    setIsMobileNavShow(is);
                  }}
                />
              </div>
              {/* <MobileHeder /> */}
            </div>
          </div>
          {/* <div className="fw-pseudo-wrapper">
            <div className="desktop-header"></div>
          </div> */}
        </header>
        <div className="fw-pseudo-wrapper fw-pseudo-wrapper-slider"></div>
      </div>
    </>
  );
}
