import { Link } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import RatingStars from "../../../../Helpers/RatingStars";
import Endpoint from "../../../../Utils/Endpoint";
import { useHistory } from "react-router-dom";
import { NoData } from "Helpers/NoData";

import BanglaBazarApi from "../../../Api/BanglaBazarApi";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { HiChevronRight } from "react-icons/hi2";

function OrderList({ Orders }) {
  let history = useHistory();

  const [modal, setModal] = useState(false);
  const [order, setOrder] = useState();

  const toggleModal = (order = null) => {
    console.log("===========bbbbbbbb===============");
    if (!modal) {
      setOrder(order);
    }
    setModal(!modal);
  };

  const [IsCollpase, setIsCollpased] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let GetPriceOrVariation = (indexedOrder, status) => {
    let currentOrder = indexedOrder;
    let basePrice = parseFloat(currentOrder.BasePrice);
    let combinationPrice = 0.0;
    let variations = [];
    let combinations = currentOrder.ProductCombinations;
    for (let i = 0; i < combinations.length; i++) {
      combinationPrice += parseFloat(combinations[i].ProductCombinationPrice);
      variations.push(combinations[i].OptionValue);
    }
    let sum = combinationPrice + basePrice;
    let variationString = variations.join(" , ");
    if (status === 0) {
      return sum;
    } else {
      return variationString;
    }
  };

  let getDateDifference = (orderDate) => {
    var now = moment(new Date());
    var end = moment(orderDate).format("YYYY-MM-DD");
    var duration = moment.duration(now.diff(end));
    var days = duration.asDays();

    return days >= 14 ? false : true;
    // return true;
  };

  const handleDeleteClick = async (order) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to proceed with the deletion?", // Changed text here
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6f9642", // Bootstrap "success" color
      cancelButtonColor: "#d33", // Bootstrap "danger" color
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true); // Show loader

        try {
          const response = await BanglaBazarApi.post(
            `${Endpoint}/api/payment/delete-pending-order`,
            {
              orderNumber: order.OrderNumber,
            }
          );

          setIsLoading(false); // Hide loader

          // Check if the response is successful
          if (response) {
            toast.success("Pending Order Deleted Successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            // Handle error here
            Swal.fire("Error", "Something went wrong!", "error");
          }
        } catch (error) {
          setIsLoading(false); // Hide loader in case of error
          // Handle error here
          Swal.fire("Error", "Network error!", "error");
        }
      }
    });
  };

  return Orders.length < 1 ? (
    <div
      className="text-center mt-2"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minheight: "100px",
      }}
    >
      <NoData />
    </div>
  ) : (
    <>
      <div className="row content-inner">
        <div
          id="center_column"
          className="center_column col-xs-12 col-sm-12 col-sm-push-0"
        >
          {/* <h1 className="page-heading bottom-indent">Order List</h1> */}
          <div className="block-center mt-1" id="block-history">
            <table
              id="order-list"
              className="table table-bordered footab default footable-loaded footable text-center order-table"
            >
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Date</th>
                  <th>Total Tax</th>
                  <th>Total Shipping</th>
                  <th>Total Paid</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Orders.map((order, index) => (
                  <tr index={index}>
                    <td>
                      <b>{order.OrderNumber}</b>
                    </td>
                    <td>{moment(order.OrderDate).format("YYYY-MM-DD")}</td>
                    <td>
                      {order.ProductDetail[0].Currency}{" "}
                      {(order && order.EstimatedTax) || "0.0"}
                    </td>
                    <td>
                      {order.ProductDetail[0].Currency}{" "}
                      {order &&
                        new Intl.NumberFormat().format(order.ShippingHandling)}
                    </td>
                    <td>
                      {order.ProductDetail[0].Currency}{" "}
                      {order &&
                        new Intl.NumberFormat().format(order.OrderTotal)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {order.PaymentStatus.toLowerCase() === "failed" && (
                        <button
                          type="button"
                          class="btn btn-sm rounded-pill"
                          style={{
                            backgroundColor: "#FF3D00",
                            color: "white",
                            fontWeight: "600",
                          }}
                        >
                          Payment Failed
                        </button>
                      )}
                      {order.PaymentStatus.toLowerCase() === "cancel" && (
                        <button
                          type="button"
                          class="btn btn-sm rounded-pill"
                          style={{
                            backgroundColor: "darkgray",
                            color: "white",
                            fontWeight: "600",
                          }}
                        >
                          Payment cancelled
                        </button>
                      )}
                      {order.PaymentStatus.toLowerCase() === "paid" && (
                        <button
                          type="button"
                          class="btn btn-sm btn-success rounded-pill"
                          style={{
                            backgroundColor: "#4CAF50",
                            color: "white",
                            fontWeight: "600",
                          }}
                        >
                          Paid
                        </button>
                      )}
                      {order.PaymentStatus.toLowerCase() === "pending" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "row",
                          }}
                        >
                          <button
                            type="button"
                            class="btn btn-sm btn-warning rounded-pill"
                            style={{
                              backgroundColor: "#FF9100",
                              color: "white",
                              fontWeight: "600",
                            }}
                          >
                            Pending
                          </button>
                          {/* <button
                            style={{
                              background: "transparent",
                              border: "none",
                              padding: 0,
                              margin: 0,
                              cursor: "pointer",
                              color: "red",
                              marginLeft: "8px",
                            }}
                            onClick={() => handleDeleteClick(order)}
                          >
                            <i className="fas fa-trash"></i>
                          </button> */}
                        </div>
                      )}

                      {order.PaymentStatus.toLowerCase() === "unpaid" && (
                        <button
                          type="button"
                          class="btn btn-sm btn-danger rounded-pill"
                        >
                          Unpaid
                        </button>
                      )}
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Link
                          to={`/order-details/${order.OrderNumber}`}
                          className="btn btn-success"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                        {order.PaymentStatus.toLowerCase() === "pending" &&
                          order.PaymentType == "cod" &&
                          order.ProcessStatus == "Processing" &&
                          order.AllowStorePickup == "Y" && (
                            <button
                              className="btn btn-success"
                              style={{
                                marginLeft: 8,
                              }}
                              onClick={() => handleDeleteClick(order)}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {order && (
              <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Order Details</ModalHeader>
                <ModalBody>
                  {order &&
                    (order.DeliveryStatus === "usps_intl" ||
                      order.DeliveryStatus === "dhl") && (
                      <div>
                        {" "}
                        <span className="text-orange">
                          Global orders cannot be refunded
                        </span>
                      </div>
                    )}
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <span>
                        Transaction ID :{" "}
                        <Link
                          to={`/order-details/${order.OrderNumber}`}
                          className="text-orange"
                        >
                          {order.TransactionID}
                        </Link>
                      </span>
                    </div>

                    {order &&
                      order.DeliveryDate &&
                      order.DeliveryStatus != "usps_intl" &&
                      order.s != "dhl" &&
                      getDateDifference(order.DeliveryDate) && (
                        <div>
                          {" "}
                          <span>
                            <Link
                              to={`/request-refund/${order.OrderNumber}`}
                              className="text-dark"
                            >
                              Request Refund
                            </Link>
                          </span>
                        </div>
                      )}
                  </div>
                  {order.ProductDetail.map((item, index) => (
                    <div className="row mt-2" key={index}>
                      <div className="col-2 ">
                        <a>
                          <img
                            className="w-100"
                            style={{
                              maxWidth: "fit-content",
                              height: "75px",
                            }}
                            src={`${Endpoint}/${item.Small}`}
                            onClick={() =>
                              history.push(`/product-details/${item.ProductID}`)
                            }
                          />
                        </a>
                      </div>
                      <div className="col-6 m-auto">
                        <div>
                          <div
                            style={{
                              cursor: "pointer",
                              ":hover": {
                                color: "blue",
                              },
                            }}
                            onClick={() =>
                              history.push(`/product-details/${item.ProductID}`)
                            }
                          >
                            <h5 className="mb-0">{item.Title}</h5>
                          </div>

                          <div className="pt-1 pb-1">
                            <p className="mb-0" style={{ fontWeight: "600" }}>
                              Variations: {GetPriceOrVariation(item, 1)}
                            </p>
                          </div>
                          <div
                            className="text-orange"
                            style={{ fontSize: "13px" }}
                          >
                            Price: {item.Currency}{" "}
                            {GetPriceOrVariation(item, 0)}
                          </div>
                        </div>
                      </div>
                      <div className="col-4 m-auto">
                        <span className="dotd-rate">
                          {RatingStars(
                            item.AVG_Rating ? parseInt(item.AVG_Rating) : 0
                          )}{" "}
                          ({item.REVIEW_COUNT})
                        </span>
                      </div>
                    </div>
                  ))}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleModal}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderList;
