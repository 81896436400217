import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetCartItems, calculateTotalPriceFromCart } from "Actions/CartAction";
import { RemoveCartItems } from "Actions/CartAction";
import Loading from "Utils/Loading";
import { CurrentUser } from "Helpers/Auth";
import BanglaBazarApi from "Components/Api/BanglaBazarApi";
import Endpoint from "Utils/Endpoint";
import firetoast from "Helpers/FireToast";
import MODAL_CONTEXT from "Components/Contexts/ModalContext";

function MyCart() {
  const state = useSelector((state) => state);
  const history = useHistory();
  const { addCartItem, getCartItem, removeCartItem } = state;
  const dispatch = useDispatch();
  const [TotalPrice, setTotalPrice] = useState(null);
  const [IsLoading, setIsLoading] = useState(false);
  const [CartItems, setCartItems] = useState([]);
  const [ProductCombinationItems, setProductCombinationItems] = useState([]);
  const { signin, setSignin } = useContext(MODAL_CONTEXT);
  useEffect(() => {
    dispatch(GetCartItems());
    SetAndFetchData();
  }, [
    dispatch,
    addCartItem,
    getCartItem.loading,
    // CartItems,
    // ProductCombinationItems,
  ]);

  useEffect(() => {
    setIsLoading(true);
    // dispatch(GetCartItems());
    setIsLoading(false);
    SetAndFetchData();
  }, [removeCartItem, dispatch]);

  var SetAndFetchData = async () => {
    setIsLoading(true);
    var localCart = localStorage.getItem("uLC");
    if (CurrentUser) {
      if (!getCartItem.loading) {
        if (getCartItem.error) {
          setCartItems([]);
        } else {
          var response = await BanglaBazarApi.get(
            `${Endpoint}/api/wish-list/viewCart`
          );

          var tempCart = response.data.productCartList;
          calculateTotalPriceFromCart(response.data.productCartList, response.data.productCombinationPriceDetail);

          setCartItems(tempCart);

          var tempCombinations = response.data.productCombinationPriceDetail;

          var totalCount = 0;
          let tempCombination = response.data.productCombinationPriceDetail;
          let indexes = response.data.productCartList;

          for (let i = 0; i < indexes.length; i++) {
            let currentProduct = response.data.productCartList[i];
            const product = currentProduct;
            let basePrice = parseFloat(product.Price);

            const currentDate = new Date();

            // Parse PromotionStartDate and PromotionEndDate if they are strings
            const promotionStartDate = new Date(product.PromotionStartDate);
            const promotionEndDate = new Date(product.PromotionEndDate);
            // Check if the current date is within the promotion period
            if (
              currentDate >= promotionStartDate &&
              currentDate <= promotionEndDate
            ) {
              if (product.PromotionPrice > 0) {
                basePrice = parseFloat(product.PromotionPrice);
              } else if (product.PromotionRate > 0) {
                const discount =
                  parseFloat(product.Price) * (product.PromotionRate / 100);
                basePrice = parseFloat(product.Price) - discount;
              }
            }

            let currentCombination = tempCombination[i];
            totalCount += basePrice;
            for (let j = 0; j < currentCombination?.length; j++) {
              totalCount += parseFloat(
                currentCombination[j].ProductCombinationPrice
              );
            }
            totalCount = totalCount * parseInt(currentProduct.Total_Quantity);
          }

          setTotalPrice(totalCount);

          console.log("totalCount", totalCount);

          setProductCombinationItems(tempCombinations);
        }
      }
    } else if (localCart) {
      var productCartList = [];
      var productCombinationPriceDetail = [];
      var tempCart = JSON.parse(localCart);
      for (let i = 0; i < tempCart.length; i++) {
        var currentParsedItem = JSON.parse(tempCart[i]).ProductDetail[0];
        var product = currentParsedItem.ProductDetail;
        product["Total_Quantity"] = currentParsedItem.Quantity;
        productCartList.push(product);
        productCombinationPriceDetail.push(
          currentParsedItem.ProductCombinations
        );
      }
      var tempCart = productCartList;
      setCartItems(tempCart);
      var tempCombinations = productCombinationPriceDetail;
      var totalCount = 0;
      let tempCombination = productCombinationPriceDetail;
      let indexes = productCartList;
      for (let i = 0; i < indexes.length; i++) {
        let currentProduct = productCartList[i];

        const product = currentProduct;
        let basePrice = parseFloat(product.Price);

        const currentDate = new Date();

        // Parse PromotionStartDate and PromotionEndDate if they are strings
        const promotionStartDate = new Date(product.PromotionStartDate);
        const promotionEndDate = new Date(product.PromotionEndDate);
        // Check if the current date is within the promotion period
        if (
          currentDate >= promotionStartDate &&
          currentDate <= promotionEndDate
        ) {
          if (product.PromotionPrice > 0) {
            basePrice = parseFloat(product.PromotionPrice);
          } else if (product.PromotionRate > 0) {
            const discount =
              parseFloat(product.Price) * (product.PromotionRate / 100);
            basePrice = parseFloat(product.Price) - discount;
          }
        }

        let currentCombination = tempCombination[i];
        totalCount += basePrice;
        for (let j = 0; j < currentCombination.length; j++) {
          totalCount += parseFloat(currentCombination[j].TotalPrice);
        }
        totalCount = totalCount * parseInt(currentProduct.Total_Quantity);
      }
      setTotalPrice(totalCount);
      setProductCombinationItems(tempCombinations);
    }

    setIsLoading(false);
  };

  var removeUnAuthCartItem = async (index) => {
    var tempCartItems = [];
    var tempProductCombinationItems = [];
    for (let i = 0; i < CartItems.length; i++) {
      if (i !== index) {
        tempCartItems.push(CartItems[i]);
        tempProductCombinationItems.push(ProductCombinationItems[i]);
      }
    }
    setCartItems(tempCartItems);
    setProductCombinationItems(tempProductCombinationItems);
    var localCart = JSON.parse(localStorage.getItem("uLC"));
    var tempCart = [];
    for (let i = 0; i < localCart.length; i++) {
      if (i !== index) {
        tempCart.push(localCart[i]);
      }
    }
    localStorage.setItem("uLC", JSON.stringify(tempCart));
    // console.log(
    //   tempProducts.splice(index, 1),
    //   tempCombinations.splice(index, 1)
    // );
  };

  var deleteCartItem = async (ProductID, index) => {
    console.log(index);
    var productVariations = ProductCombinationItems[index];
    console.log(ProductCombinationItems);
    var ProductVariantCombinationDetail = [];
    for (let i = 0; i < productVariations.length; i++) {
      var obj = {};
      obj.ProductVariantCombinationID =
        productVariations[i].ProductVariantCombinationID;
      ProductVariantCombinationDetail.push(obj);
    }
    let array1 = [];
    let array2 = [];
    for (let i = 0; i < CartItems.length; i++) {
      if (i !== index) {
        array1.push(CartItems[i]);
        array2.push(ProductCombinationItems[i]);
      }
    }
    if (array2.length === 0) setTotalPrice("");
    dispatch(RemoveCartItems(ProductID, { ProductVariantCombinationDetail }));
    setCartItems(array1);
    setProductCombinationItems(array2);
  };

  let currency = localStorage.getItem("currency");

  //   let newValue = '';

  // if (!currency) {
  //     let region = localStorage.getItem("region");
  //     currency = region === "Bangladesh" ? 'BDT' : localStorage.getItem("currency")
  //     // localStorage.setItem("currency", currency);
  // }

  return (
    <>
      {IsLoading && CurrentUser && getCartItem.loading ? (
        <Loading text={"Getting Cart Item"} />
      ) : (
        <div class="shopping_cart">
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (CurrentUser) {
                history.push("/my-cart");
              } else {
                setSignin(!signin);
              }
            }}
            title="View my shopping cart"
            rel="nofollow"
          >
            <span class="cart_name">Cart</span>
            <div class="more_info">
              <span
                class="ajax_cart_total unvisible"
                style={{ display: "inline" }}
              >
                {CartItems && CartItems.length > 0 ? (
                  <>
                    {CartItems[0].Currency}&nbsp;
                    {localStorage.getItem("totalCartItemsAmountFromViewCart")
                      ? new Intl.NumberFormat().format(
                        localStorage.getItem("totalCartItemsAmountFromViewCart")
                      )
                      : TotalPrice
                        ? new Intl.NumberFormat().format(TotalPrice.toFixed(2))
                        : ""}
                  </>
                ) : (
                  <>empty</>
                )}
              </span>
            </div>
          </Link>
        </div>
      )}
    </>
  );
}
export default MyCart;
